<template>
  <div>
    <Navbar />
    <div class="container">

      <div class="row mt-3 mb-1" style="font-size: 15px">
        <div
          class="col-md-4"
          v-text="'Surah ' + nama + ' (' + judul + ')'"
        ></div>
        <div class="col-md-4" v-text="'The Place : ' + tempat"></div>
      </div>
      <div class="row mt-1 mb-1" style="font-size: 15px">
        <div class="col-md-4" v-text="'Surah : ' + surat"></div>
        <div class="col-md-4" v-text="'Type : ' + tipe"></div>
      </div>
      <div class="row mt-1 mb-3" style="font-size: 15px">
        <div class="col-md-4" v-text="'Total of Ayah : ' + ayat"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4" style="text-align: right">
          <button class="btn btn-info" @click="pindahhalaman">
            <b-icon-arrow-right></b-icon-arrow-right> Next Surah
          </button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-md-12" v-for="data in kumpulan" :key="data.id">
          <div class="card shadow card-product mt-2">
            <h4 style="text-align: right" class="mt-3 mr-3">
              {{ data.text + " (" + data.number + ")" }}
            </h4>
            <hr>
            <span class="ml-2 mr-2 mb-2">{{data.translation_id}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "SurahDetail",
  components: {
    Navbar,
  },
  data() {
    return {
      kumpulan: {},
      nama: "",
      judul: "",
      ayat: "",
      surat: "",
      tempat: "",
      tipe: "",
    };
  },
  methods: {
    setKumpulan(data) {
      this.kumpulan = data.verses;
      this.nama = data.name;
      this.judul = data.name_translations.ar;
      this.ayat = data.number_of_ayah;
      this.surat = data.number_of_surah;
      this.tempat = data.place;
      this.tipe = data.type;
    },
    pindahhalaman() {
      window.location.href =
        "/surahdetail/" + (parseInt(this.$route.params.id) + 1);
    },
  },
  mounted() {
    axios
      .get(
        "https://raw.githubusercontent.com/penggguna/QuranJSON/master/surah/" +
          this.$route.params.id +
          ".json",
        this.data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((response) => this.setKumpulan(response.data)) //console.log(response.data['verses'][0]['text'])
      .catch((error) => console.log(error));
  },
};
</script>

<style>
</style>