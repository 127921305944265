<template>
  <div class="hero">
    <!-- desktop -->
    <div class="d-none d-md-block">
      <!--d-none d-md-block ==> agar tidak ditampilkan di mobile -->
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="d-flex h-100">
            <div class="justify-content-center align-self-center">
              <h2>
                The Holy Quran <strong>Anywhere</strong> and
                <strong>Anytime</strong>
              </h2>
              <p>Reading more easy, comfortable, and flexible</p>
              <router-link class="btn btn-lg btn-info" to="/surah">
                <b-icon-list></b-icon-list> List View
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img src="../assets/baca.png" width="100%" alt="" />
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="d-sm-block d-md-none">
      <!--d-sm-block d-md-none ==> agar tidak ditampilkan di desktop -->
      <div class="row mt-4">
        <div class="col-md-6 mb-3">
          <img src="../assets/baca.png" width="100%" alt="" />
        </div>
        <div class="col-md-6">
          <div class="d-flex h-100">
            <div class="justify-content-center align-self-center">
              <h2>
                The Holy Quran <strong>Anywhere</strong> and
                <strong>Anytime</strong>
              </h2>
              <p>Reading more easy, comfortable, and flexible</p>
              <router-link class="btn btn-lg btn-info" to="/surah">
                <b-icon-list></b-icon-list> List View
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style>
</style>