<template>
  <div>
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="#">
        <img src="../assets/logoku.png" width="50" alt="" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
            <b-nav-item href="#"><router-link class="nav-link" to="/">Home</router-link></b-nav-item>
            <b-nav-item href="#"><router-link class="nav-link" to="/surah">List of Surah</router-link></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
    name:'Navbar'
};
</script>

<style>
</style>