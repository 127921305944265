<template>
  <div>
    <Navbar />
    <dir class="container">
      <!-- <h2>kumpulan surat</h2> -->

      <!-- <div class="row mt-3">
        <div class="col">
          <div class="input-group mb-3">
            <input
              v-model="search"
              type="text"
              class="form-control"
              placeholder="Cari Makanan Kesukaan Anda"
              aria-label="Cari"
              aria-describedby="basic-addon1"
              @keyup="searchSurat"
            />
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"
                ><b-icon-search></b-icon-search
              ></span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row mt-3">
        <div class="col-md-4 mt-4" v-for="data in kumpulan" :key="data.id">
          <div class="card shadow card-product">
            <h3 style="text-align: right" class="mt-3">
              <span class="mr-5">{{ data.name }}</span>
              <span class="mr-4">{{ data.name_translations.ar }}</span>
            </h3>
            <div class="card-body">
              <!-- <h5 class="card-title">{{ data.name }}</h5> -->
              <p class="card-text">Total of Ayah : {{ data.number_of_ayah }}</p>
              <p class="card-text">
                Surah : {{ data.number_of_surah }}
              </p>
              <p class="card-text">Placed : {{ data.place }}</p>
              <p class="card-text">Type of Ayah : {{ data.type }}</p>
              <router-link class="btn btn-info" :to="'/surahdetail/'+ data.number_of_surah"
                ><b-icon-book></b-icon-book> Read this</router-link
              >
              <audio controls class="mt-3">
                <source :src="data.recitation" type="audio/mpeg" />
              </audio>
            </div>
          </div>
        </div>
      </div>
    </dir>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "Surah",
  components: {
    Navbar,
  },
  data() {
    return {
      kumpulan: [],
      search: "",
    };
  },
  methods: {
    setKumpulan(data) {
      this.kumpulan = data;
    },
    // searchSurat() {
    //   axios
    //     .get("https://raw.githubusercontent.com/penggguna/QuranJSON/master/quran.json?q="+this.search)
    //     .then((response) => this.setKumpulan(response.data))
    //     .catch((error) => console.log(error));
    // },
  },
  mounted() {
    axios
      .get(
        "https://raw.githubusercontent.com/penggguna/QuranJSON/master/quran.json",
        this.data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((response) => this.setKumpulan(response.data)) //console.log(response.data[0]['name'])
      .catch((error) => console.log(error));
  },
};
</script>

<style>
</style>